import Perfrom from "../Desgin/build";
import Optim from "../Desgin/optim";
import Figma from "../Desgin/figma";
import Help from "../Desgin/help";
import Work from "../Desgin/work";
import Cursor from "../Cursor";
function Design() {
  return (
    <>
      <Perfrom />
      <Optim />
      <Figma />
      <Help />
      <Work />
      <Cursor />
    </>
  )
}
export default Design;