import React from 'react'
import Cursor from '../Cursor';
import Bar from '../Blog/bar';
import Mark from '../Blog/Mark';
import ScrollTo from '../Blog/scroll';
import IMAGES from '../Allfiles/image';

function Privacy() {
  return (
    <>
     <Bar />
      <Mark />
      <div className="blogdata">
        <div className="wrapper">
          <div className="container">
            <div className='term-blog'>
              <div className='blog-section-h1'>
                <h1>Privacy Policy </h1>
              </div>
              <div className="blogpage-paragraph">
                <p>At EcombitHub, accessible from www.EcombitHub.com, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by EcombitHub and how we use it.</p>
              </div>
              <div className="blogpage-paragraph">
                <p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.</p>
              </div>
              <div className="blogpage-paragraph">
                <p>This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collected in EcombitHub. This policy is not applicable to any information collected offline or via channels other than this website.</p>
              </div>
              <div className="blogpage-paragraph-list">
                <p><span style={{ fontWeight: 700 }}>INFORMATION WE COLLECT</span></p>
              </div>
              <div className="blogpage-paragraph">
                <p>The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.</p>
              </div>
              <div className="blogpage-paragraph">
                <p>If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.</p>
              </div>
              <div className="blogpage-paragraph-list">
                <p><span style={{ fontWeight: 700 }}>HOW WE USE YOUR INFORMATION</span></p>
              </div>
              <div className="blogpage-paragraph-list">
                <p>We use the information we collect in various ways, including to:
                </p>
              </div>
              <div className="blogpage-paragraph">
                <p>Improve, personalize, and expand our website.</p>
              </div>
              <div className="blogpage-paragraph">
                <p>Understand and analyze how you use our website. </p>
              </div>
              <div className="blogpage-paragraph">
                <p>Develop new products, services, features, and functionality.              </p>
              </div>
              <div className="blogpage-paragraph">
                <p>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes.</p>
              </div>
              <div className="blogpage-paragraph-list">
                <p>Send you emails. </p>
              </div>
              <div className="blogpage-paragraph">
                <p>Find and prevent fraud.              </p>
              </div>
              <div className="blogpage-paragraph-list">
                <p><span style={{ fontWeight: 700 }}>LEGAL REQUIREMENTS</span></p>
              </div>
              <div className="blogpage-paragraph">
                <p>Agreement terms of services shall be governed and construed by the laws of India, which governing law applies to the User Agreement without regard to its conflict of law provisions. Any disputes relating to these Terms of Services and User Agreements will be subject to the exclusive jurisdiction of the courts of India.              </p>
              </div>
              <div className="blogpage-paragraph-list">
                <p><span style={{ fontWeight: 700 }}>YOUR CHOICES</span></p>
              </div>
              <div className="blogpage-paragraph">
                <p>You can opt out of receiving promotional emails from us by following the unsubscribe link in our emails. You can also contact us to update your personal information or request that we delete your personal information.</p>
              </div>
              <div className="blogpage-paragraph-list">
                <p><span style={{ fontWeight: 700 }}>SECURITY</span></p>
              </div>
              <div className="blogpage-paragraph">
                <p>We use reasonable administrative, technical, and physical measures to safeguard your personal information against loss, theft, and unauthorized access, use, and disclosure. However, no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>
              </div>
              <div className="blogpage-paragraph-list">
                <p><span style={{ fontWeight: 700 }}>CHILDREN'S  INFORMATION</span></p>
              </div>
              <div className="blogpage-paragraph">
                <p>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p>
              </div>
              <div className="blogpage-paragraph">
                <p>EcombitHub does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</p>
              </div>
              <div className="blogpage-paragraph-list">
                <p><span style={{ fontWeight: 700 }}>CONTACT US</span></p>
              </div>
              <div className="blogpage-paragraph">
                <p>If anything mentioned in this privacy policy seems confusing or less clear, please feel free to reach out by writing to info@hubsyntax.com, any kind of question or doubt concerned to our privacy policies are welcome.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScrollTo />
      <Cursor />
    </>
  )
}

export default Privacy
