import Web from "../Build/Web";
import Meet from "../Build/Meet";
import Grow from "../Build/Grow";
import Power from "../Build/Power";
import Create from "../Build/Create";
import Online from "../Build/Online";
// import Get from "..Create/Build_Form/Get";
import Cursor from "../Cursor";
function Build() {

    return (
        <>
            <Web />
            <Meet />
            <Grow />
            <Power />
            <Create />
            <Online />
            <Cursor />
        </>
    )
}
export default Build;