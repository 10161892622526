import IMAGES from "../Allfiles/image";
function Figma() {
    return (
        <>
            <div className="design--section-perform">
                <div className='wrapper'>
                    <div className='container'>
                <div className='design--section-mobile'>
                    <h2>Responsive Design / Mobile first Design approach</h2>
                </div>
                <div className='design--section-mobile-image'>
                    <img src={IMAGES.w21}/>
                </div>
                </div>
                </div>
            </div>
        </>
    )
}
export default Figma;