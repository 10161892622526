import g1 from '../images/Group 26.webp'
import g2 from '../images/Group 27.webp'
import g3 from '../images/Group 29.webp'
import g4 from '../images/Group 30.webp'
import g5 from '../images/Group 31.webp'
import g6 from '../images/Group 33.webp'
import g7 from '../images/Group 35.webp'
import g11 from '../images/62e1784d32ece27b4ee7733f.webp'
import g13 from '../images/Group 32.webp'
import g14 from '../images/Group 34.webp'
import g15 from '../images/Group 36.webp'
import aa10 from '../images/yoyo.webp'
import VectorSmartObject from '../images/vector1.webp';
import Layer2 from '../images/Layer 577 copy 2.webp';
import Layer577 from '../images/Layer 577.webp';
import speedometer100 from '../images/speedometer100.webp';
import store102 from '../images/store102.webp';
import rocket102 from '../images/rocket102.webp';
import Group024 from '../images/Group024.webp';
import responsive from '../images/responsive.webp';
import default1 from '../images/default1.webp';
import circle from '../images/circle.webp';
import redcircle from '../images/redcircle.webp';
import icons from '../images/icons.webp';
import border from '../images/border.webp';
import redbox from '../images/redbox.webp';
import section1 from '../images/small11.webp';
import section2 from '../images/small12.webp';
import section3 from '../images/small13.webp';
import section4 from '../images/small14.webp';
import section5 from '../images/small15.webp';
import section6 from '../images/small16.webp';
import section7 from '../images/small17.webp';
import section8 from '../images/small18.webp';
import third101 from '../images/big11.webp';
import third102 from '../images/big12.webp';
import third103 from '../images/big13.webp';
import third104 from '../images/big14.webp';
import third106 from '../images/big15.webp';
import third107 from '../images/big16.webp';
import third108 from '../images/big17.webp';
import third109 from '../images/big18.webp';
import shopify1 from '../images/shopify1.webp';
import hub from '../images/hub.webp';
import web from '../images/web.webp';
import shopify10 from '../images/shopify10.webp';
import figma from '../images/figma.webp';
import shopify12 from '../images/shopify12.webp';
import plus from '../images/plus.webp';
import spot from '../images/spot.webp';
import flow from '../images/flow.webp';
import fig from '../images/fig.webp';
import scroll1 from '../images/scroll1.webp';
import scroll2 from '../images/scroll2.webp';
import scroll3 from '../images/scroll5.webp';
import scroll4 from '../images/scroll4.webp';
import scroll5 from '../images/scroll3.webp'
import scroll6 from '../images/scroll6.webp'
import scroll7 from '../images/scroll7.webp'
import scroll8 from '../images/scroll8.webp'
import expert from '../images/expert.webp'
import shopifyf from '../images/shopifyf.webp'
import one0 from '../images/one0.webp'
import one2 from '../images/one2.webp'
import one5 from '../images/one5.webp'
import one6 from '../images/one6.webp'
import que from '../images/que.webp'
import com from '../images/com.webp'
import two0 from '../images/two0.webp'
import two2 from '../images/two2.webp'
import oo1 from '../images/oo1.webp'
import nek from '../images/nek.webp'
import two3 from '../images/two3.webp'
import two4 from '../images/two4.webp'
import men from '../images/men.webp'
import email from '../images/email.webp'
import calling from '../images/calling.webp'
import url from '../images/url.webp'
import name from '../images/name.webp'
import map2 from '../images/map2.webp'
import cal from '../images/cal.webp'
import mail from '../images/mail.webp'
import atsecond from '../images/website.webp'
import shoping1 from '../images/shoping1.webp'
import shoping2 from '../images/shoping2.webp'
import shoping3 from '../images/shoping3.webp'
import Mobileabout from '../images/Mobileabout.webp'
import mobile201 from '../images/mobile201.webp'
import mobile200 from '../images/mobile200.webp'
import appbg from '../images/stand.webp'
import brand1 from '../images/brand1.webp'
import brand2 from '../images/brand2.webp'
import brand3 from '../images/brand3.webp'
import brand4 from '../images/brand4.webp'
import brand5 from '../images/brand5.webp'
import brand6 from '../images/brand6.webp'
import brand7 from '../images/brand7.webp'
import brand8 from '../images/brand8.webp'
import brand9 from '../images/brand9.webp'
import brand10 from '../images/brand10.webp'
import content from '../images/content.webp'
import content1 from '../images/content1.webp'
import content2 from '../images/content2.webp'
import trend from '../images/apppage1.webp'
import bithub from '../images/bithub.webp'
import blank from '../images/blank.webp'
import Image1 from '../images/Image21.webp'
import Image2 from '../images/Image22.webp'
import Image3 from '../images/Image23.webp'
import Image4 from '../images/Image24.webp'
import Image5 from '../images/Image25.webp'
import Image6 from '../images/image26.webp'
import Image7 from '../images/image27.webp'
import Image8 from '../images/image28.webp'
import Image9 from '../images/Image29.webp'
import Image10 from '../images/Image30.webp'
import Image11 from '../images/Image31.webp'
import Image12 from '../images/Image32.webp'
import starrr120 from '../images/starrr120.webp'
import Ellipse1 from '../images/Ellipse1.webp'
import Ellipse from '../images/Ellipse.webp'
import fum2 from '../images/fum2.webp'
import fum102 from '../images/fum102.webp'
import codester from '../images/codester.webp'
import codester1 from '../images/codester1.webp'
import psy from '../images/psy.webp'
import psy1 from '../images/psy1.webp'
import psy2 from '../images/psy2.webp'
import psy3 from '../images/psy3.webp'
import tes2 from '../images/tes2.webp'
import tes1 from '../images/tes1.webp'
import neola from '../images/neola.webp'
import neola1 from '../images/neola1.webp'
import clientlogo1 from '../images/clientlogo1.webp'
import clientlogo from '../images/clientlogo.webp'
import cart101 from '../images/cart101.webp'
import cart102 from '../images/cart102.webp'
import cart103 from '../images/cart103.webp'
import cart104 from '../images/cart104.webp'
import cart105 from '../images/cart105.webp'
import cart106 from '../images/cart106.webp'
import cart107 from '../images/cart107.webp'
import cart108 from '../images/cart108.webp'
import cart109 from '../images/cart109.webp'
import cart110 from '../images/cart110.webp'
import filter10 from '../images/filter22.webp'
import dynamic10 from '../images/dynamic220.webp'
import effort10 from '../images/effort222.webp'
import webbrowser from '../images/webbrowser22.webp'
import secure10 from '../images/secure22.webp'
import paintswatch from '../images/paintswatch222.webp'
import scale1000 from '../images/scale22.webp'
import gps10 from '../images/gps222.webp'
import memorycard from '../images/memorycard222.webp'
import personalization from '../images/personalization22.webp'
import recomendation from '../images/recomendation222.webp'
import dynamic2000 from '../images/dynamic22.webp'
import menu10000 from '../images/menu222.webp'
import marketing from '../images/marketing.webp'
import wishlist22 from '../images/wishlist22.webp'
import recommendation22 from '../images/recommendation22.webp'
import touch22 from '../images/touch22.webp'
import buy22 from '../images/buy22.webp'
import ass1 from '../images/ass1.webp'
import speed1 from '../images/speed11.webp'
import speed2 from '../images/speed12.webp'
import speed3 from '../images/speed13.webp'
import speed4 from '../images/speed14.webp'
import speed21 from '../images/speed21.webp'
import speed22 from '../images/speed22.webp'
import speed23 from '../images/speed23.webp'
import speed24 from '../images/speed24.webp'
import speed25 from '../images/speed25.webp'
import spd from '../images/spd.webp'
import spd1 from '../images/spd1.webp'
import check1 from '../images/check1.webp'
import check2 from '../images/check2.webp'
import check3 from '../images/check3.webp'
import build50 from '../images/build50.webp'
import build49 from '../images/build49.webp'
import build48 from '../images/build48.webp'
import build47 from '../images/build47.webp'
import build46 from '../images/build46.webp'
import build45 from '../images/build45.webp'
import aboutfirst from '../images/aboutbanner.webp'
import header from '../images/header.webp';
import page from '../images/pagefly.webp';
import fly from '../images/fle.webp';
import whitelogo from '../images/whitelogo.webp'
import graphic101 from '../images/graphic101.webp'
import structure from '../images/structure.webp'
import prototyping from '../images/prototyping.webp'
import user1012 from '../images/user1012.webp'
import code11 from '../images/code22.webp'
import upselling from '../images/upselling22.webp'
import checkout00 from '../images/checkout22.webp'
import notes1000 from '../images/notes22.webp'
import email159 from '../images/email222.webp'
import mail22 from '../images/mail22.webp'
import marketing22 from '../images/marketing22.webp'
import chatbot22 from '../images/chatbot22.webp'
import n2 from '../images/n2.webp'
import n1 from '../images/node.webp'
import secondcontact from '../images/secondcontact.webp'
import home from '../images/Home.webp'
import Service from '../images/Service.webp'
import about from '../images/about.webp'
import blog from '../images/blog.webp'
import content0 from '../images/content0.webp'
import Build1 from '../images/Build1.png'
import settings from '../images/settings1.png'
import brand123 from '../images/brand123.webp'
import storebk from '../images/storebk.webp'
import sidebarstore from '../images/sidebarstore2.webp'
import storecircle from '../images/storecircle.webp'
import storeshopify from '../images/storeshopify.webp'
import buildblog from '../images/buildblog12.webp'
import storeweb from '../images/storeweb.png'
import rename1 from '../images/rename1.webp'
import custom1 from '../images/custom1.webp'
import custom2 from '../images/storepage00.webp'
import cro1 from '../images/custom123.webp'
import redmore from '../images/redmore.webp'
import email00 from '../images/email00.png'
const IMAGES = {
  email00:email00,
  redmore:redmore,
  cro1:cro1,
  custom2:custom2,
  custom1:custom1,
  rename1:rename1,
  storeweb:storeweb,
  buildblog:buildblog,
  storeshopify:storeshopify,
  storecircle:storecircle,
  sidebarstore:sidebarstore,
  storebk:storebk,
  brand123:brand123,
  settings:settings,
  Build1:Build1,
  content0:content0,
  blog:blog,
  Service:Service,
  about:about,
  home:home,
  secondcontact:secondcontact,
  n1:n1,
  n2:n2,
  chatbot22:chatbot22,
  marketing22:marketing22,
  mail22:mail22,
  email159:email159,
  notes1000:notes1000,
  checkout00:checkout00,
  upselling:upselling,
  code11:code11,
  user1012:user1012,
  prototyping:prototyping,
  structure:structure,
  graphic101:graphic101,
  whitelogo:whitelogo,
  blank:blank,
  trend:trend,
  bithub:bithub,
  fly:fly,
  page:page,
  header:header,
  aboutfirst:aboutfirst,

  build45:build45,
  build46:build46,
  build47:build47,
  build48:build48,
  build49:build49,
  build50:build50,
  check3:check3,
  check2:check2,
  check1:check1,
  spd1:spd1,
  spd:spd,
  speed21:speed21,
  speed25:speed25,
  speed22:speed22,
  speed23:speed23,
  speed24:speed24,
  speed2:speed2,
  speed3:speed3,
  speed4:speed4,
  speed1:speed1,

  ass1:ass1,
  buy22:buy22,
  touch22:touch22,
  recommendation22:recommendation22,
  wishlist22:wishlist22,
  marketing:marketing,
  menu10000:menu10000,
  dynamic2000:dynamic2000,
  recomendation:recomendation,
  personalization:personalization,
  memorycard:memorycard,
  gps10:gps10,
  scale1000:scale1000,
  paintswatch:paintswatch,
  secure10:secure10,
  webbrowser:webbrowser,
  effort10:effort10,
  dynamic10:dynamic10,
  filter10:filter10,
  cart102: cart102,
  cart103: cart103,
  cart104: cart104,
  cart105: cart105,
  cart106: cart106,
  cart107: cart107,
  cart108: cart108,
  cart109: cart109,
  cart110: cart110,
  cart101: cart101,
  clientlogo1: clientlogo1,
  clientlogo: clientlogo,
  neola: neola,
  neola1: neola1,
  tes2: tes2,
  tes1: tes1,
  psy2: psy2,
  psy3: psy3,
  psy: psy,
  psy1: psy1,
  codester: codester,
  codester1: codester1,
  fum102: fum102,
  fum2: fum2,
  Ellipse1: Ellipse1,
  Ellipse: Ellipse,
  starrr120: starrr120,
  Image1: Image1,
  Image2: Image2,
  Image3: Image3,
  Image4: Image4,
  Image5: Image5,
  Image6: Image6,
  Image7: Image7,
  Image8: Image8,
  Image9: Image9,
  Image10: Image10,
  Image11: Image11,
  Image12: Image12,
  content1: content1,
  content2: content2,
  content: content,
  brand10: brand10,
  brand9: brand9,
  brand8: brand8,
  brand7: brand7,
  brand6: brand6,
  brand5: brand5,
  brand4: brand4,
  brand3: brand3,
  brand2: brand2,
  brand1: brand1,
  appbg: appbg,
  mobile200: mobile200,
  mobile201: mobile201,
  Mobileabout: Mobileabout,
  shoping2: shoping2,
  shoping3: shoping3,
  shoping1: shoping1,
  atsecond: atsecond,
  mail: mail,
  cal: cal,
  map2: map2,
  name: name,
  url: url,
  calling: calling,
  email: email,
  men: men,
  section8: section8,
  section7: section7,
  section6: section6,
  third107: third107,
  third108: third108,
  third109: third109,
  two4: two4,
  two3: two3,
  nek: nek,
  oo1: oo1,
  two2: two2,
  two0: two0,
  com: com,
  que: que,
  one6: one6,
  one5: one5,
  one2: one2,
  one0: one0,
  shopifyf: shopifyf,
  expert: expert,
  third106: third106,
  scroll8: scroll8,
  scroll7: scroll7,
  scroll6: scroll6,
  scroll5: scroll5,
  scroll1: scroll1,
  scroll2: scroll2,
  scroll3: scroll3,
  scroll4: scroll4,
  fig: fig,
  flow: flow,
  spot: spot,
  plus: plus,
  shopify12: shopify12,
  figma: figma,
  shopify10: shopify10,
  web: web,
  hub: hub,
  shopify1: shopify1,
  third102: third102,
  third103: third103,
  third104: third104,
  third101: third101,
  section5: section5,
  section4: section4,
  section3: section3,
  section2: section2,
  section1: section1,
  redbox: redbox,
  border: border,
  icons: icons,
  redcircle: redcircle,
  circle: circle,
  default1: default1,
  responsive: responsive,
  Group024: Group024,
  rocket102: rocket102,
  store102: store102,
  speedometer100: speedometer100,
  Layer577: Layer577,
  Layer2: Layer2,
  VectorSmartObject: VectorSmartObject,
  aa10: aa10,
  g1: g1,
  g2: g2,
  g3: g3,
  g4: g4,
  g5: g5,
  g6: g6,
  g7: g7,
  g11: g11,
  g13: g13,
  g14: g14,
  g15: g15,
}
export default IMAGES;


