import IMAGES from "../Allfiles/image";
function Expert() {
    return (
        <>
            <div className="expsion--section">
                <div className="wrapper">
                        <div className='expsion--section-main'>
                            <div className="expsion--section-main-side">
                                <div className="expsion--section-first">
                                    <img src={IMAGES.atsecond} />
                                </div>
                                <div className="expsion--section-second">
                                    <div className="expsion--section-second-side">
                                        <h2>Discover EcombitHub Your Ultimate Shopify Solution Partner</h2>
                                        <p>Our platform is a launchpad for entrepreneurs and businesses, providing comprehensive and innovative Shopify solutions available. Our goal is to streamline your e-commerce journey with expert support, and customized strategies that drive success. With EcombitHub, you gain access to a dedicated partner focused on unlocking your full potential in the digital marketplace.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                 
                </div>
            </div >
        </>
    )
}
export default Expert;