import React from 'react';

function Loader() {
    return (
        <div className="animation">
            <div className="circle">
                <div className="circle2"></div>
            </div>
        </div>
    );
}

export default Loader;